import axios  from "axios";
import { getToken } from "@/utils/setToken.js";
import { Message } from "element-ui";
import Cookies from 'js-cookie'
import router from '@/router'

const service  = axios.create({
    baseURL: process.env.VUE_APP_URL,// baseURL会自动加载到请求地址上
    timeout: 50000
})

//添加请求拦截器
service.interceptors.request.use((config) =>{
    //在请求之前（获取并设置token)
    // if(Cookies.get('token')){
    //     config.headers['token'] = getToken('token');
    // }
    config.headers['token'] = getToken('token');
    return config
},(error) => {
    return Promise.reject(error)
})


//添加响应拦截器
let refreshIndex = 0
service.interceptors.response.use((response) => {
 // 对响应数据做点什么，状态码为2xx
    const res = response.data
    if (res.code === '000000') {
        if (refreshIndex !== 0) refreshIndex = 0
        return res
    } else if (res.code === '401') {
        if (refreshIndex === 0) {
            Message({
                message: '登录过期，请重新登录',
                type: 'error',
                duration: 5 * 1000
            })
            Cookies.remove('token')

            window.localStorage.clear()
            router.replace({
                name: 'login',
                query: {
                    redirect: router.currentRoute.fullPath
                }
            })
            refreshIndex += 1
        }
    } else {
        Message({
            message: res.msg || 'Error',
            type: 'error',
            duration: 5 * 1000
        })
        return res
    }
},(error) => {
    // 对响应错误做点什么，状态码为200+

    Message({
    message: '网络超时',
    type: 'error',
    duration: 5 * 1000
    })
    return Promise.reject(error)
})

export default service

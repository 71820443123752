import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'font-awesome/css/font-awesome.min.css'
import './assets/css/common.css'
import router from './router'
import service from './service'
import LoadPlugin from './components/FullLoading'
import JSEncrypt from 'jsencrypt'
// import axios  from "axios";

Vue.use(ElementUI)
Vue.use(LoadPlugin)
Vue.prototype.service = service
// Vue.prototype.axios = axios
// service.defaults.baseURL='http://192.168.4.6:9180'
Vue.prototype.UploadPath='/data/importfile/'
Vue.config.productionTip = false
Vue.prototype.$jsEncrypt = JSEncrypt
Vue.directive('focus', function (el) {
  el.querySelector('input').focus()
})

//路由导航守卫
router.beforeEach((to, from, next) => {
  if(!sessionStorage.getItem('isLogin')){
    window.localStorage.clear();
    if(to.path != '/login'){
      next('/login')
    }else next()
  } next()
  // if(!localStorage.getItem('userInfo') && !localStorage.getItem('token')){
  //   if(to.path != '/login'){
  //     next('/login')
  //   }else next()
  // } next()
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')


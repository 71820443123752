import Vue from 'vue'
import Router from 'vue-router'
import nprogress from 'nprogress' // @types/nprogress
import 'nprogress/nprogress.css'// 进度条css
import Cookies from 'js-cookie'


Vue.use(Router)

const routes = [
  // {
  //   path: '/',
  //   // component: Home
  //   name: '登录页',
  //   redirect: '/login',
  //   hidden: true,
  //   component: () => import('@/components/Login') //路由懒加载
  // },
  {
    path: '/login',
    name: 'Login',
    hidden: true,
    component: () => import('@/components/Login') //路由懒加载
  },
  {
    path: '*',
    name: 'NotFound',
    hidden: true,
    component: () => import('@/components/NotFound') //路由懒加载
  },

  {
    path: '/home',
    name: 'home',
    iconClass: 'fa fa-university',
    redirect: '/home/maintain',
    component: () => import('@/components/Home'),
    children: [
      {
        path: '/home/maintain',
        name: '主页',
        iconClass: 'fa fa-list',
        component: () => import('@/views/home/index.vue')
      },
      {
        path: '/home/userInfo',
        name: 'home-userInfo',
        meta: {
          title: '个人信息修改'
        },
        iconClass: 'fa fa-file-text',
        component: () => import('@/views/home/userInfo.vue'),
      }
      // {
      //   path: '/home/maintain',
      //   name: '维保信息提醒',
      //   iconClass: 'fa fa-list',
      //   component: () => import('@/views/home/MaintenanceInfo')
      // }
    ]
  },
  {
    path: '/device',
    name: 'device',
    meta: {
      title: '仪器仪表管理'
    },
    iconClass: 'fa fa-thermometer-empty',
    component: () => import('@/components/Home'),
    children: [
      {
        path: '/device/deviceInfo',
        name: 'device-info',
        meta: {
          title: '基本信息管理'
        },
        iconClass: 'fa fa-align-justify',
        redirect: '/device/deviceInfo/list',
        component: () => import('@/views/device/deviceInfo/index.vue'),
        children: [
          {
            path: '/device/deviceInfo/list',
            name: 'deviceInfo-list',
            component: () => import('@/views/device/deviceInfo/list.vue')
          },
          {
            path: '/device/deviceInfo/:id/edit',
            name: 'deviceInfo-edit',
            meta: {
              title: '修改仪器仪表'
            },
            component: () => import('@/views/device/deviceInfo/edit.vue'),
            props: true
          },
          {
            path: '/device/deviceInfo/create',
            name: 'deviceInfo-create',
            meta: {
              title: '新增仪器仪表'
            },
            component: () => import('@/views/device/deviceInfo/create.vue')
          },
          {
            path: '/device/deviceInfo/:id/detail',
            name: 'deviceInfo-detail',
            meta: {
              title: '仪器仪表详情'
            },
            component: () => import('@/views/device/deviceInfo/detail.vue'),
            props: true
          },
          {
            path: '/device/deviceInfo/:did/addKeepLog',
            name: 'deviceInfo-addKeepLog',
            meta: {
              title: '新增维护保养记录'
            },
            component: () => import('@/views/device/deviceInfo/keepLog/add.vue'),
            props: true
          },
          {
            path: '/device/deviceInfo/chgLocal',
            name: 'deviceInfo-chgLocal',
            meta: {
              title: '位置更换'
            },
            redirect: '/device/deviceInfo/chgLocal/add',
            component: () => import('@/views/device/deviceInfo/chgLocal/index.vue'),
            children: [
              {
                path: '/device/deviceInfo/chgLocal/add',
                name: 'deviceInfo-chgLocal-add',
               
                component: () => import('@/views/device/deviceInfo/chgLocal/add.vue'),
                props: true,
              },
              // {
              //   path: '/device/deviceInfo/deviceAnnex/list',
              //   name: 'deviceInfo-chgLocal-list',
              //   component: () => import('@/views/device/deviceInfo/chgLocal/list.vue'),
              //   props: true,
              // },
              {
                path: '/device/deviceInfo/chgLocal/:id/detail',
                name: 'deviceInfo-chgLocal-detail',
                meta: {
                  title: '位置更换详情'
                },
                component: () => import('@/views/device/deviceInfo/chgLocal/detail.vue'),
                props: true,
              }
            ]
          },
          {
            path: '/device/deviceInfo/:did/deviceAnnex',
            name: 'deviceInfo-deviceAnnex',
            meta: {
              title: '装备附件'
            },
            redirect: '/device/deviceInfo/:did/deviceAnnex/list',
            component: () => import('@/views/device/deviceInfo/deviceAnnex/index.vue'),
            props: true,
            children: [
              {
                path: '/device/deviceInfo/:did/deviceAnnex/list',
                name: 'deviceInfo-annex-list',
                component: () => import('@/views/device/deviceInfo/deviceAnnex/list.vue'),
                props: true,
              },
              {
                path: '/device/deviceInfo/:did/deviceAnnex/create',
                name: 'deviceInfo-annex-create',
                meta: {
                  title: '新增装备附件'
                },
                component: () => import('@/views/device/deviceInfo/deviceAnnex/create.vue'),
                props: true,
              },
              {
                path: '/device/deviceInfo/:did/deviceAnnex/:id/edit',
                name: 'deviceInfo-annex-edit',
                meta: {
                  title: '修改装备附件'
                },
                component: () => import('@/views/device/deviceInfo/deviceAnnex/edit.vue'),
                props: true,
              }
            ]

          },
        ]
      },
      {
        path: '/device/whManage',
        name: 'device-wh',
        meta: {
          title: '库中管理'
        },
        redirect: '/device/whManage/list',
        iconClass: 'fa fa-home',
        component: () => import('@/views/device/whManage/index.vue'),
        children: [
          {
            path: '/device/whManage/list',
            name: 'whManage-list',
            component: () => import('@/views/device/whManage/list.vue')
          },
          {
            path: '/device/whManage/:id/detail',
            name: 'whManage-detail',
            meta: {
              title: '仪器仪表详情'
            },
            component: () => import('@/views/device/whManage/detail.vue'),
            props: true
          },
          {
            path: '/device/whManage/:did/addKeepLog',
            name: 'whManage-addKeepLog',
            meta: {
              title: '新增维护保养记录'
            },
            component: () => import('@/views/device/whManage/keepLog/add.vue'),
            props: true
          },
          {
            path: '/device/whManage/chgLocal',
            name: 'whManage-chgLocal',
            meta: {
              title: '位置更换'
            },
            redirect: '/device/whManage/chgLocal/add',
            component: () => import('@/views/device/whManage/chgLocal/index.vue'),
            children: [
              {
                path: '/device/whManage/chgLocal/add',
                name: 'whManage-chgLocal-add',
                // meta: {
                //   title: '仪器仪表位置更换'
                // },
                component: () => import('@/views/device/whManage/chgLocal/add.vue'),
                props: true,
              },
              // {
              //   path: '/device/deviceInfo/deviceAnnex/list',
              //   name: 'deviceInfo-chgLocal-list',
              //   component: () => import('@/views/device/deviceInfo/chgLocal/list.vue'),
              //   props: true,
              // },
              {
                path: '/device/whManage/chgLocal/:id/detail',
                name: 'whManage-chgLocal-detail',
                meta: {
                  title: '位置更换详情'
                },
                component: () => import('@/views/device/whManage/chgLocal/detail.vue'),
                props: true,
              }
            ]
          },
          {
            path: '/device/whManage/:did/deviceAssess',
            name: 'whManage-deviceAssess',
            meta: {
              title: '状态评估'
            },
            redirect: '/device/whManage/:did/deviceAssess/list',
            component: () => import('@/views/device/whManage/deviceAssess/index.vue'),
            props: true,
            children: [
              {
                path: '/device/whManage/:did/deviceAssess/list',
                name: 'whManage-deviceAssessc-list',
                component: () => import('@/views/device/whManage/deviceAssess/list.vue'),
                props: true,
              },
              {
                path: '/device/whManage/:did/deviceAssess/create',
                name: 'whManage-deviceAssess-add',
                meta: {
                  title: '新增状态评估'
                },
                component: () => import('@/views/device/whManage/deviceAssess/create.vue'),
                props: true,
              },
              {
                path: '/device/whManage/:did/deviceAssess/:id/edit',
                name: 'whManage-deviceAssess-edit',
                meta: {
                  title: '修改状态评估'
                },
                component: () => import('@/views/device/whManage/deviceAssess/edit.vue'),
                props: true,
              },
              {
                path: '/device/whManage/:did/deviceAssess/:id/detail',
                name: 'whManage-deviceAssess-detail',
                meta: {
                  title: '状态评估详情'
                },
                component: () => import('@/views/device/whManage/deviceAssess/detail.vue'),
                props: true,
              }
            ]
          },

        ]
      },
      {
        path: '/device/delete',
        name: 'delete-info',
        meta: {
          title: '信息删除管理'
        },
        iconClass: 'fa fa-align-justify',
        redirect: '/device/delete/list',
        component: () => import('@/views/device/delete/index.vue'),
        children: [
          {
            path: '/device/delete/list',
            name: 'delete-list',
            component: () => import('@/views/device/delete/list.vue')
          },
          {
            path: '/device/delete/:id/detail',
            name: 'delete-detail',
            meta: {
              title: '仪器仪表详情'
            },
            component: () => import('@/views/device/delete/detail.vue'),
            props: true
          },
          {
            path: '/device/delete/:did/deviceAnnex',
            name: 'delete-deviceAnnex',
            meta: {
              title: '装备附件'
            },
            redirect: '/device/delete/:did/deviceAnnex/list',
            component: () => import('@/views/device/delete/deviceAnnex/index.vue'),
            props: true,
            children: [
              {
                path: '/device/delete/:did/deviceAnnex/list',
                name: 'delete-annex-list',
                component: () => import('@/views/device/delete/deviceAnnex/list.vue'),
                props: true,
              }
            ]

          },
        ]
      },
      {
        path: '/device/equipManage',
        name: 'equipManage',
        meta: {
          title: '装备包管理'
        },
        redirect: '/device/equipManage/list',
        iconClass: 'fa fa-suitcase',
        component: () => import('@/views/device/equipManage/index.vue'),
        children: [
          {
            path: '/device/equipManage/list',
            name: 'equipManage-list',
            component: () => import('@/views/device/equipManage/list.vue'),
          },
          {
            path: '/device/equipManage/create',
            name: 'equipManage-create',
            meta: {
              title: '新增装备包'
            },
            component: () => import('@/views/device/equipManage/create.vue'),
          },
          {
            path: '/device/equipManage/:id/edit',
            name: 'equipManage-edit',
            meta: {
              title: '编辑装备包'
            },
            component: () => import('@/views/device/equipManage/edit.vue'),
            props: true,
          },
          {
            path: '/device/equipManage/:id/detail',
            name: 'equipManage-detail',
            meta: {
              title: '装备包详情'
            },
            component: () => import('@/views/device/equipManage/detail.vue'),
            props: true,
          },
          {
            path: '/device/equipManage/:id/ckInventory',
            name: 'equipManage-ckInventory',
            meta: {
              title: '装备包出库'
            },
            component: () => import('@/views/device/equipManage/ckInventory.vue'),
            props: true,
          }
        ]
      },
      {
        path: '/device/keepLog',
        name: 'device-keepLog',
        meta: {
          title: '保养维护管理'
        },
        redirect: '/device/keepLog/list',
        iconClass: 'fa fa-home',
        component: () => import('@/views/device/keepLog/index.vue'),
        children: [
          {
            path: '/device/keepLog/list',
            name: 'keepLog-list',
            component: () => import('@/views/device/keepLog/list.vue'),
          },
          {
            path: '/device/keepLog/:id/edit',
            name: 'keepLog-edit',
            meta: {
              title: '修改仪器仪表保养维护'
            },
            component: () => import('@/views/device/keepLog/edit.vue'),
            props: true,
          },
          {
            path: '/device/keepLog/:id/:type/detail',
            name: 'keepLog-detail',
            meta: {
              title: '仪器仪表保养维护详情'
            },
            component: () => import('@/views/device/keepLog/detail.vue'),
            props: true,
          }
        ]
      },
      {
        path: '/device/oiManage',
        name: 'device-oiM',
        meta: {
          title: '出入库管理'
        },
        redirect: '/device/oiManage/list',
        iconClass: 'fa fa-truck',
        component: () => import('@/views/device/oiManage/index.vue'),
        children: [
          {
            path: '/device/oiManage/list',
            name: 'device-oi-list',
            component: () => import('@/views/device/oiManage/list.vue')
          },
          {
            path: '/device/oiManage/ckmanage',
            name: 'device-oi-ckmanage',
            meta: {
              title: '仪器仪表出库'
            },
            component: () => import('@/views/device/oiManage/ckmanage.vue')
          },
          {
            path: '/device/oiManage/rkmanage',
            name: 'device-oi-rkmanage',
            meta: {
              title: '仪器仪表入库'
            },
            component: () => import('@/views/device/oiManage/rkmanage.vue')
          },
          {
            path: '/device/oiManage/:id:type/detail',
            name: 'device-oi-detail',
            meta: {
              title: '出入库详情'
            },
            component: () => import('@/views/device/oiManage/detail.vue'),
            props: true
          }
        ]
      },
      {
        path: '/device/annex',
        name: 'device-annex',
        meta: {
          title: '附件管理'
        },
        redirect: '/device/annex/list',
        iconClass: 'fa fa-truck',
        component: () => import('@/views/device/annex/index.vue'),
        children: [
          {
            path: '/device/annex/list',
            name: 'annex-list',
            component: () => import('@/views/device/annex/list.vue')
          }
        ]
      },
     
    ]
  },
  {
    path: '/team',
    name: 'team',
    meta: {
      title: '班组安全工器具管理'
    },
    iconClass: 'fa fa-gavel',
    component: () => import('@/components/Home'),
    children: [
      {
        path: '/team/ledgerManage',
        name: 'team-ledger',
        meta: {
          title: '台账管理'
        },
        redirect: '/team/ledgerManage/list',
        iconClass: 'fa fa-file-text',
        component: () => import('@/views/team/ledgerManage/index.vue'),
        children: [
          {
            path: '/team/ledgerManage/list',
            name: 'team-ledger-list',
            component: () => import('@/views/team/ledgerManage/list.vue')
          },
          {
            path: '/team/ledgerManage/create',
            name: 'team-ledger-create',
            meta: {
              title: '新增班组安全工器具'
            },
            component: () => import('@/views/team/ledgerManage/create.vue')
          },
          {
            path: '/team/ledgerManage/:id/edit',
            name: 'team-ledger-edit',
            meta: {
              title: '修改班组安全工器具'
            },
            component: () => import('@/views/team/ledgerManage/edit.vue'),
            props: true
          },
          {
            path: '/team/ledgerManage/:id/detail',
            name: 'team-ledger-detail',
            meta: {
              title: '班组安全工器具详情'
            },
            component: () => import('@/views/team/ledgerManage/detail.vue'),
            props: true
          }
        ]
      },
      {
        path: '/team/experiment',
        name: 'team-experiment',
        meta: {
          title: '试验管理'
        },
        iconClass: 'fa fa-hourglass-half',
        redirect: '/team/experiment/list',
        component: () => import('@/views/team/experiment/index.vue'),
        children: [
          {
            path: '/team/experiment/list',
            name: 'team-exper-list',
            component: () => import('@/views/team/experiment/list.vue')
          },
          {
            path: '/team/experiment/:tid/test',
            name: 'team-exper-test',
            meta: {
              title: '试验报告'
            },
            redirect: '/team/experiment/:tid/test/list',
            component: () => import('@/views/team/experiment/test/index.vue'),
            props: true,
            children: [
              {
                path: '/team/experiment/:tid/test/list',
                name: 'team-exper-test-list',
                component: () => import('@/views/team/experiment/test/list.vue'),
                props: true,
              },
              {
                path: '/team/experiment/:tid/test/create',
                name: 'team-exper-test-create',
                meta: {
                  title: '新增试验报告'
                },
                component: () => import('@/views/team/experiment/test/create.vue'),
                props: true,
              },
              {
                path: '/team/experiment/:tid/test/:id/edit',
                name: 'team-exper-test-edit',
                meta: {
                  title: '修改试验报告'
                },
                component: () => import('@/views/team/experiment/test/edit.vue'),
                props: true,
              },
              {
                path: '/team/experiment/:tid/test/:id/detail',
                name: 'team-exper-test-detail',
                meta: {
                  title: '试验报告详情'
                },
                component: () => import('@/views/team/experiment/test/detail.vue'),
                props: true,
              },
            ]

          },
        ]
      },
     
    ]
  },
  {
    path: '/station',
    name: 'station',
    meta: {
      title: '变电站安全工器具管理'
    },
    iconClass: 'fa fa-gavel',
    component: () => import('@/components/Home'),
    children: [
      {
        path: '/station/ledgerManage',
        name: 'st-ledger',
        meta: {
          title: '台账管理'
        },
        redirect: '/station/ledgerManage/list',
        iconClass: 'fa fa-file-text',
        component: () => import('@/views/station/ledgerManage/index.vue'),
        children: [
          {
            path: '/station/ledgerManage/list',
            name: 'station-ledger-list',
            component: () => import('@/views/station/ledgerManage/list.vue')
          },
          {
            path: '/station/ledgerManage/create',
            name: 'station-ledger-create',
            meta: {
              title: '新增变电站安全工器具'
            },
            component: () => import('@/views/station/ledgerManage/create.vue')
          },
          {
            path: '/station/ledgerManage/:id/edit',
            name: 'station-ledger-edit',
            meta: {
              title: '修改变电站安全工器具'
            },
            component: () => import('@/views/station/ledgerManage/edit.vue'),
            props: true
          },
          {
            path: '/station/ledgerManage/:id/detail',
            name: 'station-ledger-detail',
            meta: {
              title: '变电站安全工器具详情'
            },
            component: () => import('@/views/station/ledgerManage/detail.vue'),
            props: true
          }
        ]
      },
      {
        path: '/station/experiment',
        name: 'st-experiment',
        meta: {
          title: '试验管理'
        },
        redirect: '/station/experiment/list',
        iconClass: 'fa fa-hourglass-half',
        component: () => import('@/views/station/experiment/index.vue'),
        children: [
          {
            path: '/station/experiment/list',
            name: 'station-exper-list',
            component: () => import('@/views/station/experiment/list.vue')
          },
          {
            path: '/station/experiment/:sid/test',
            name: 'station-exper-test',
            meta: {
              title: '试验报告'
            },
            redirect: '/station/experiment/:sid/test/list',
            component: () => import('@/views/station/experiment/test/index.vue'),
            props: true,
            children: [
              {
                path: '/station/experiment/:sid/test/list',
                name: 'station-exper-test-list',
                component: () => import('@/views/station/experiment/test/list.vue'),
                props: true,
              },
              {
                path: '/station/experiment/:sid/test/create',
                name: 'station-exper-test-create',
                meta: {
                  title: '新增试验报告'
                },
                component: () => import('@/views/station/experiment/test/create.vue'),
                props: true,
              },
              {
                path: '/station/experiment/:sid/test/:id/edit',
                name: 'station-exper-test-edit',
                meta: {
                  title: '修改试验报告'
                },
                component: () => import('@/views/station/experiment/test/edit.vue'),
                props: true,
              },
              {
                path: '/station/experiment/:sid/test/:id/detail',
                name: 'station-exper-test-detail',
                meta: {
                  title: '试验报告详情'
                },
                component: () => import('@/views/station/experiment/test/detail.vue'),
                props: true,
              },
            ]

          },
        ]
      }
    ]
  },
  {
    path: '/auxiliary',
    name: 'auxiliary',
    meta: {
      title: '辅助工具管理'
    },
    iconClass: 'fa fa-suitcase',
    component: () => import('@/components/Home'),
    children: [
      {
        path: '/auxiliary/ledgerManage',
        name: 'au-ledger',
        meta: {
          title: '台账管理'
        },
        iconClass: 'fa fa-file-text',
        redirect: '/auxiliary/ledgerManage/list',
        component: () => import('@/views/auxiliary/ledgerManage/index.vue'),
        children: [
          {
            path: '/auxiliary/ledgerManage/list',
            name: 'auxilLedger-list',
            component: () => import('@/views/auxiliary/ledgerManage/list.vue')
          },
          {
            path: '/auxiliary/ledgerManage/:id/edit',
            name: 'auxilLedger-edit',
            meta: {
              title: '修改辅助工具'
            },
            component: () => import('@/views/auxiliary/ledgerManage/edit.vue'),
            props: true
          },
          {
            path: '/auxiliary/ledgerManage/create',
            name: 'auxilLedger-create',
            meta: {
              title: '新增辅助工具'
            },
            component: () => import('@/views/auxiliary/ledgerManage/create.vue')
          },
          {
            path: '/auxiliary/ledgerManage/:id/detail',
            name: 'auxilLedger-detail',
            meta: {
              title: '辅助工具详情'
            },
            component: () => import('@/views/auxiliary/ledgerManage/detail.vue'),
            props: true
          }
        ]
      },
      {
        path: '/auxiliary/oiManage',
        name: 'au-oiM',
        meta: {
          title: '出入库管理'
        },
        redirect: '/auxiliary/oiManage/list',
        iconClass: 'fa fa-truck',
        component: () => import('@/views/auxiliary/oiManage/index.vue'),
        children: [
          {
            path: '/auxiliary/oiManage/list',
            name: 'auxil-oi-list',
            component: () => import('@/views/auxiliary/oiManage/list.vue')
          },
          {
            path: '/auxiliary/oiManage/ckmanage',
            name: 'auxil-oi-ckmanage',
            meta: {
              title: '辅助工具出库'
            },
            component: () => import('@/views/auxiliary/oiManage/ckmanage.vue')
          },
          {
            path: '/auxiliary/oiManage/rkmanage',
            name: 'auxil-oi-rkmanage',
            meta: {
              title: '辅助工具入库'
            },
            component: () => import('@/views/auxiliary/oiManage/rkmanage.vue')
          },
          {
            path: '/auxiliary/oiManage/:id:type/detail',
            name: 'auxil-oi-detail',
            meta: {
              title: '出入库详情'
            },
            component: () => import('@/views/auxiliary/oiManage/detail.vue'),
            props: true
          }
        ]
      },
      {
        path: '/auxiliary/scrapManage',
        name: 'scrapManage',
        meta: {
          title: '报废管理'
        },
        redirect: '/auxiliary/scrapManage/list',
        iconClass: 'fa fa-bitbucket-square',
        component: () => import('@/views/auxiliary/scrapManage/index.vue'),
        children: [
          {
            path: '/auxiliary/scrapManage/list',
            name: 'auxilScrap-list',
            component: () => import('@/views/auxiliary/scrapManage/list.vue')
          },
          {
            path: '/auxiliary/scrapManage/:id/detail',
            name: 'auxilScrap-detail',
            meta: {
              title: '辅助工具详情'
            },
            component: () => import('@/views/auxiliary/scrapManage/detail.vue'),
            props: true
          }
        ]
      },
     
    ]
  },
  {
    path: '/spare',
    name: 'spare',
    meta: {
      title: '备品备件管理'
    },
    iconClass: 'fa fa-thumb-tack',
    component: () => import('@/components/Home'),
    children: [
      {
        path: '/spare/ledgerManage',
        name: 'spare-ledgerManage',
        meta: {
          title: '台账管理'
        },
        iconClass: 'fa fa-file-text',
        redirect: '/spare/ledgerManage/list',
        component: () => import('@/views/spare/ledgerManage/index.vue'),
        children: [
          {
            path: '/spare/ledgerManage/list',
            name: 'spareLedger-list',
            component: () => import('@/views/spare/ledgerManage/list.vue')
          },
          {
            path: '/spare/ledgerManage/:id/edit',
            name: 'spareLedger-edit',
            meta: {
              title: '修改备品备件'
            },
            component: () => import('@/views/spare/ledgerManage/edit.vue'),
            props: true
          },
          {
            path: '/spare/ledgerManage/create',
            name: 'spareLedger-create',
            meta: {
              title: '新增备品备件'
            },
            component: () => import('@/views/spare/ledgerManage/create.vue')
          },
          {
            path: '/spare/ledgerManage/:id/edit',
            name: 'spareLedger-detail',
            meta: {
              title: '备品备件详情'
            },
            component: () => import('@/views/spare/ledgerManage/detail.vue'),
            props: true
          }
        ]
      },
      
    ]
  },
  {
    path: '/system',
    name: 'system',
    meta: {
      title: '系统管理'
    },
    iconClass: 'fa fa-cog',
    component: () => import('@/components/Home'),
    children: [
      {
        path: '/system/user',
        name: 'user',
        meta: {
          title: '用户管理'
        },
        iconClass: 'fa fa-user',
        redirect: '/system/user/list',
        component: () => import('@/views/system/user/index.vue'),
        children: [
          {
            path: '/system/user/list',
            name: 'user-list',
            component: () => import('@/views/system/user/list.vue')
          },
          {
            path: '/system/user/:userId/edit',
            name: 'user-edit',
            // name: '修改用户',
            component: () => import('@/views/system/user/edit.vue'),
            props: true
          },
          {
            path: '/system/user/create',
            name: 'user-create',
            // name: '新增用户',
            component: () => import('@/views/system/user/create.vue')
          }
        ]
      },
      {
        path: '/system/role',
        name: 'role',
        meta: {
          title: '角色管理'
        },
        iconClass: 'fa fa-users',
        redirect: '/system/role/list',
        component: () => import('@/views/system/role/index.vue'),
        children: [
          {
            path: '/system/role/list',
            name: 'role-list',
            component: () => import('@/views/system/role/list.vue')
          },
          {
            path: '/system/role/:roleId/edit',
            name: 'role-edit',
            component: () => import('@/views/system/role/edit.vue'),
            props: true
          },
          {
            path: '/system/role/create',
            name: 'role-create',
            // name: '新增用户',
            component: () => import('@/views/system/role/create.vue')
          }
        ]
      },
      {
        path: '/system/permission',
        name: 'permission',
        meta: {
          title: '权限管理'
        },
        iconClass: 'fa fa-suitcase',
        component: () => import('@/views/system/permission/index.vue')
      },
      {
        path: '/system/sysLog',
        name: 'sysLog',
        meta: {
          title: '系统日志'
        },
        redirect: '/system/sysLog/list',
        iconClass: 'fa fa-clock-o',
        component: () => import('@/views/system/sysLog/index.vue'),
        children: [
          {
            path: '/system/sysLog/list',
            name: 'sysLog-list',
            component: () => import('@/views/system/sysLog/list.vue')
          },
          {
            path: '/system/sysLog/:id/detail',
            name: 'sysLog-detail',
            component: () => import('@/views/system/sysLog/detail.vue'),
            props: true
          }
        ]
      }
    ]
  },
  {
    path: '/basis',
    name: 'basis',
    meta: {
      title: '基础信息管理'
    },
    iconClass: 'fa fa-cog',
    component: () => import('@/components/Home'),
    children: [
      {
        path: '/device/keep',
        name: 'device-annex',
        meta: {
          title: '仪器仪表名称管理'
        },
        redirect: '/device/keep/list',
        iconClass: 'fa fa-truck',
        component: () => import('@/views/device/keep/index.vue'),
        children: [
          {
            path: '/device/keep/list',
            name: 'keep-list',
            component: () => import('@/views/device/keep/list.vue')
          }
        ]

      },
      {
        path: '/device/class',
        name: 'device-class',
        meta: {
          title: '仪器仪表分类管理'
        },
        redirect: '/device/class/list',
        iconClass: 'fa fa-truck',
        component: () => import('@/views/device/class/index.vue'),
        children: [
          {
            path: '/device/class/list',
            name: 'deviceClass-list',
            component: () => import('@/views/device/class/list.vue')
          },
          {
            path: '/device/class/:id/edit',
            name: 'deviceClass-edit',
            meta: {
              title: '修改仪器仪表分类'
            },
            component: () => import('@/views/device/class/edit.vue'),
            props: true
          },
          {
            path: '/device/class/create',
            name: 'deviceClass-create',
            meta: {
              title: '新增仪器仪表分类'
            },
            component: () => import('@/views/device/class/create.vue')
          }
        ]
      },
      {
        path: '/team/toolClass',
        name: 'team-toolClass',
        meta: {
          title: '工器具分类管理'
        },
        redirect: '/team/toolClass/list',
        iconClass: 'fa fa-hourglass-half',
        component: () => import('@/views/team/toolClass/index.vue'),
        children: [
          {
            path: '/team/toolClass/list',
            name: 'toolClass-list',
            component: () => import('@/views/team/toolClass/list.vue')
          },
        ]
      },
      {
        path: '/auxiliary/assistClass',
        name: 'assistClass',
        meta: {
          title: '辅助工具分类管理'
        },
        iconClass: 'fa fa-bitbucket-square',
        redirect: '/auxiliary/assistClass/list',
        component: () => import('@/views/auxiliary/assistClass/index.vue'),
        children: [
          {
            path: '/auxiliary/assistClass/list',
            name: 'assistClass-list',
            component: () => import('@/views/auxiliary/assistClass/list.vue')
          }
        ]
      },
      {
        path: '/spare/spareClass',
        name: 'spareClass',
        meta: {
          title: '备品备件分类管理'
        },
        iconClass: 'fa fa-file-text',
        redirect: '/spare/spareClass/list',
        component: () => import('@/views/spare/spareClass/index.vue'),
        children: [
          {
            path: '/spare/spareClass/list',
            name: 'spareClass-list',
            component: () => import('@/views/spare/spareClass/list.vue')
          },
          {
            path: '/spare/spareClass/:id/edit',
            name: 'spareClass-edit',
            meta: {
              title: '修改备品备件分类'
            },
            component: () => import('@/views/spare/spareClass/edit.vue'),
            props: true
          },
          {
            path: '/spare/spareClass/create',
            name: 'spareClass-create',
            meta: {
              title: '新增备品备件分类'
            },
            component: () => import('@/views/spare/spareClass/create.vue')
          }
        ]
      },
      {
        path: '/basis/warehouse',
        name: 'warehouse',
        meta: {
          title: '仓库名称管理'
        },
        iconClass: 'fa fa-bitbucket-square',
        redirect: '/basis/warehouse/list',
        component: () => import('@/views/basis/warehouse/index.vue'),
        children: [
          {
            path: '/basis/warehouse/list',
            name: 'warehouse-list',
            component: () => import('@/views/basis/warehouse/list.vue')
          }
        ]
      },
      


    ]
  },
]

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  nprogress.start() // 开始加载进度条

  // to.matched 是一个数组（匹配到是路由记录）
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!Cookies.get('token')) {
      Cookies.remove('token')

      window.localStorage.clear()
      // 跳转到登录页面
      next({
        name: 'login',
        query: { // 通过 url 传递查询字符串参数
          redirect: to.fullPath // 把登录成功需要返回的页面告诉登录页面
        }
      })
    } else {
      next() // 允许通过
    }
  } else {
    next() // 允许通过
  }
})
router.afterEach(() => {
  nprogress.done() // 结束加载进度条
})

export default router
// 设置本地缓存
export function setToken(key,value) {
    if(typeof value === 'object'){
        value = JSON.stringify(value);
    }
    window.localStorage.setItem(key,value)
}

// 获取本地缓存
export function getToken(key) {
    const data = window.localStorage.getItem(key);
    if(!data) return null
    try {
        return data
    } catch (err) {
        return null
    }
    
}

// 清楚本地缓存
export function removeToken(key) {
    window.localStorage.removeItem(key)
}